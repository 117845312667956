.navbar-content {
  height: 90px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15) !important;
  padding: 0px 75px !important;
  position: sticky !important;
  z-index: 9;
}

.navbar-logo {
  width: 80px !important;
  height: 80px !important;
}

.navbar-link {
  font-family: "Poppins", sans-serif !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.navbar-button {
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  width: 100% !important;
  margin-left: 10px !important;
}

.navbar-fabbutton .ant-float-btn-body {
  background-color: var(--primary) !important;
  border-color:  var(--primary) !important;
}

.navbar-fabbutton .ant-badge {
  background-color: var(--primary) !important;
  border-color:  var(--primary) !important;
  border-radius: 50% !important;
}

@media (max-width: 500px) {
  .navbar-content {
    height: 60px !important;
    padding: 0px 15px !important;
  }
  .navbar-logo {
    width: 50px !important;
    height: 50px !important;
  }
  .navbar-button {
    margin-left: 0px !important;
  }
}