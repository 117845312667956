.table__.left .ant-table-column-title {
  text-align: left !important;
}

.table__row_table {
  background-color: #FFF;
  padding: 15px;
  border-radius: 6px;
}
  
.table__form_filter {
  display: flex;
  justify-content: flex-end;
}
  
.table__form_filter .ant-form-item {
  margin-bottom: 0px !important;
}

.table__title {
  height: 50%;
  display: flex;
  align-items: flex-end;
  color: var(--primary);
  font-size: 20px;
}

.table__summary {
  height: 50%;
  display: flex;
  align-items: flex-start;
}

.table__summary_qtd {
  color: var(--link);
}

.table__row_buttons_addons {
  padding: 15px 0px;
}

.table__row_buttons {
  padding: 15px 0px;
  justify-content: flex-end;
}

.table__row_button {
  border-radius: 15px;
}

.table__row_button + .table__row_button {
  margin-left: 10px;
}

.table__row_button_refresh {
  border-radius: 15px;
  width: 32px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table__row_button_refresh + .table__row_button {
  margin-left: 10px;
}

.table__ table th  {
  text-align: center !important;
}

.table__actions {
  display: flex !important;
  justify-content: center !important;
}

  
.table__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}

.table__btn_view {
  background-color: rgb(36, 97, 230) !important;
}

.table__btn_view:hover, .table__btn_view:focus {
  background-color: rgba(36, 98, 230, 0.7) !important;
}

.table__btn_info {
  background-color: rgb(8, 174, 240) !important;
}

.table__btn_info:hover, .table__btn_info:focus {
  background-color: rgba(8, 174, 240, 0.7) !important;
}

.table__btn_edit {
  background-color: rgb(230, 162, 36) !important;
}

.table__btn_edit:hover, .table__btn_edit:focus {
  background-color: rgba(230, 162, 36, 0.7) !important;
}

.table__btn_delete {
  background-color: rgb(212, 45, 23) !important;
}

.table__btn_delete:hover, .table__btn_delete:focus {
  background-color: rgba(212, 45, 23, 0.7) !important;
}

.table__btn_recover {
  background-color: rgb(29, 212, 23) !important;
}

.table__btn_recover:hover, .table__btn_recover:focus {
  background-color: rgba(29, 212, 23, 0.7) !important;
}

.table__btn_gray {
  background-color: rgb(187, 187, 187) !important;
}

.table__btn_gray:hover, .table__btn_gray:focus {
  background-color: rgba(109, 109, 109, 0.7) !important;
}

.table__btn_action_1 {
  background-color: rgb(255, 121, 43) !important;
}

.table__btn_action_1:hover, .table__btn_action_1:focus {
  background-color: rgba(255, 121, 43, 0.7) !important;
}

.table__btn_cash {
  background-color: rgb(170, 43, 255) !important;
}

.table__btn_cash:hover, .table__btn_cash:focus {
  background-color: rgba(170, 43, 255, 0.7) !important;
}
