.kpi-content {
    background-color: var(--primary);
    height: 10em;
    border-radius: 8px;
    padding: 2em;
}

.kpi-content + .kpi-content,
.kpi-content + .graph-content,
.graph-content + .graph-content  {
    margin-top: 10px;
}

.kpi-title {
    color: #FFF;
    text-transform: uppercase;
    margin-top: -10px;
}

.kpi-value {
    font-size: 40px;
    color: #FFF;
    text-transform: uppercase;
    font-weight: 600;
}

.kpi-value span {
    font-size: 20px;
}

.graph-content {
    background-color: #FFF;
    border: 1px solid var(--primary);
    border-radius: 8px;
}

.graph-title {
    text-align: center;
    font-weight: 600;
    margin-top: 6px;
    margin-bottom: -26px;
}