footer.footer-component {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
    margin: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

footer.footer-component img {
    height: 50px;
}

footer.footer-component p {
    margin: 0;
}