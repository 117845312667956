@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

:root {
  --primary: #d9232d;
  --hover: #ec3740;
  --link: #556270;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: #444444 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 500px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: rgba(0,0,0,0.0) !important;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
    background-color: rgba(0,0,0,0.1);
  }
}

.text-info {
  text-align: left;
  padding-left: 10px;
  font-size: 10px;
  text-transform: uppercase;
  color: #00000080;
  cursor: default;
}

.text-area textarea {
  text-align: justify !important;
}

.no-data {
  color: #36363680;
  text-transform: uppercase;
  font-size: 10px;
}

.tag {
  padding: 3px 15px 3px 3px !important;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 5px;
  margin-top: 5px;
  color: white;
  font-weight: 600;
}

.financial_number {
  font-weight: 700;
  font-size: 14px;
}

.financial_lawyer, .clientes {
  font-weight: 600 !important;
  cursor: pointer !important;
  transition: 0.2s !important;
}

.clientes + .clientes {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #0000006e;
  margin-bottom: 10px;
}

.financial_lawyer:hover, .financial_lawyer:hover .clientes {
  color: var(--hover) !important;
}

.financial_register, .financial_filing {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  color: #0000009c;
}


.financial_title {
  font-size: 18px;
  font-weight: 700;
  border-bottom: var(--primary) 2px solid;
  margin-bottom: 20px;
}

.financial_resumo {
  font-size: 16px;
  margin-right: 10px;
  margin-top: 1px;
  width: 200px;
}

.financial_resumo_col {
  display: flex;
}

.financial_resumo_campo input {
  text-align: right !important;
}

.financial_title_table {
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
}

.financial_title_table_desc {
  font-weight: 700;
  margin-bottom: 10px;
}

.nodata {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  color: #0000006e;
}

.financial_delete {
  cursor: pointer;
  transition: 0.2s;
}

.financial_delete:hover {
  color: var(--hover);
}

.text-link {
  cursor: pointer;
  transition: 0.2s;
  color: #0000008f;
}

.text-link:hover {
  color: var(--hover);
}

.text-new {
  font-size: 42px;
  font-weight: 600;
}

.text-new-sub {
  font-size: 22px;
}

.text-new-price {
  font-size: 42px;
  text-align: right;
}

.text-new-price span {
  font-size: 30px;
}

.card-chat {
  cursor: pointer;
  padding: 6px;
  transform: .2s;
  border-radius: 8px;
  flex-wrap: nowrap;
  overflow: hidden;
}

.card-chat:hover {
  background-color: #0000002a;
}

.content-message {
  height: calc(100vh - 160px);
}

.content-message .ant-card-body {
  height: calc(100% - 56px);
  background-image: url('/public/assets/img/chat.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 0px;
}

.content-message-body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
}

.content-message-body-user {
  background-color: #FFF;
  width: 500px;
  max-width: 90%;
  box-shadow: 5px 5px 8px #0000003b;
  padding: 5px 10px;
  border-radius: 8px 8px 8px 2px;
  border-left: 4px solid var(--link);
}

.content-message-body-auth {
  background-color: #FFF;
  width: 500px;
  max-width: 90%;
  box-shadow: -5px 5px 8px #0000003b;
  padding: 5px 10px;
  border-radius: 8px 8px 2px 8px;
  float: right;
  border-right: 4px solid var(--primary);
}

.content-message-body-read {
  font-size: 11px;
  color: #00000065;
}

.last_message {
  text-wrap: nowrap;
  width: calc(100% - 70px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-download {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  cursor: pointer;
  transition: .2s;
  border-radius: 8px;
}
.btn-download:hover {
  background-color: #00000016;
}