/* BOTOES */
.btn-primary {
    background-color: var(--primary);
}
.btn-danger {
    background-color: #d10000 !important;
}
.btn-link {
    color: var(--primary) !important;
    display: flex;
    align-items: center;
    justify-content: center !important;
}
.btn-link-contato {
    color: #00000073 !important;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    margin-bottom: 10px;
}
.btn-link-contato:hover, .btn-link-contato:focus {
    color: var(--primary) !important;
}
.btn-primary:hover, .btn-primary:focus {
    background-color: var(--hover) !important;
}
.btn-danger:hover, .btn-danger:focus {
    background-color: #720000 !important;
}
.btn-default:hover, .btn-default:focus {
    color: #FFF !important;
}
.btn-link:hover, .btn-link:focus {
    color: var(--hover) !important;
}

/* TELA LOGIN */
.admin-login-background {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
  
.admin-login-content {
    background-color: #FFF;
    padding: 15px 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-content {
    height: 100vh;
    overflow-y: hidden;
}

.timeline-text {
    text-align: justify !important;
}

.admin-navbar {
    height: 60px;
    background-color: #FFF;
    box-shadow: 1px 1px 10px #00000046;
    z-index: 9 !important;
    position: fixed;
    width: 100%;
    padding: 0px 20px;
}

.admin-sidebar {
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 100px !important;
    background-color: #FFF;
    box-shadow: 1px 1px 10px #00000046;
    padding: 0px 5px;
    z-index: 8 !important;
    padding-top: 60px;
}

.admin-sidebar-item {
    width: 100%;
}

.admin-sidebar-item-link {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    background-color: #3131311a;
    height: 36px;
}

.admin-sidebar-item-link svg{
    transition: 0.2s;
}

.admin-sidebar-item-link.active svg{
    color: #FFF;
}

.admin-sidebar-item-link.active{
    color: #FFF;
    background-color: var(--primary);
}

.admin-sidebar-item-link:hover, .admin-sidebar-item-link:focus {
    background-color: var(--primary);
    color: #FFF !important;
}

.admin-sidebar-item-link:hover svg, .admin-sidebar-item-link:focus svg {
    color: #FFF !important;
}

.admin-sidebar-item-link svg {
    margin-right: 10px;
    color: #0000009a;
}

.admin-container {
    padding: 10px 10px 10px 15px;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 100px;
    padding-top: 70px;
}

.admin-form {
    background-color: #FFF;
    padding: 25px 25px;
    border-radius: 8px;
}

.admin-profile-name {
    font-size: 18px;
    width: 80%;
    margin: 0 !important;
    margin-bottom: 8px !important;
    text-align: center;
}

.admin-profile-upload {
    margin-top: -60px;
    margin-bottom: 20px;
}

.banner-profile-upload {
    position: absolute;
    bottom: 10px;
}

.ant-typography-edit-content {
    width: 80%;
    margin: 0px !important;
    padding: 0px !important;
    margin-left: 20px !important;
    margin-bottom: 8px !important;
}

@media (min-width: 768px) {
    .menu-button {
        display: none !important;
    }
}

.fabbutton {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeline-carousel {
    width: 100% !important;
    height: 400px !important;
}

.timeline-carousel .ant-image {
    width: 100% !important;
    height: 400px !important;
}

.timeline-carousel .ant-image .ant-image-img {
    object-fit: cover !important;
    object-position: center !important;
    width: 100% !important;
    height: 400px !important;
}

.timeline-date {
    font-size: 12px;
    color: #00000077;
    text-align: right;
}

.timelineA-carousel button {
    background-color: var(--primary) !important;
}

.equipe-carousel {
    padding: 0px 80px;
}

.equipe-carousel button {
    margin-top: 35px;
    background-color: var(--primary) !important;
}


.equipe-carousel-item {
    /* height: 400px; */
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.ant-card .ant-card-cover img {
    border-radius: 0px !important;
}

.timeline-title {
    font-size: 20px;
    font-weight: 600;
    text-align: justify;
    margin-bottom: 12px;
}

.equipe-title {
    font-size: 26px;
    font-weight: 600;
}

.ant-carousel:has(.timelineA-carousel) {
    border: 1px solid #f0f0f0 !important;
}

.perfil-footer {
    width: 45% !important;
}

.perfil-content {
    padding: 0px 00px;
}

.perfil-avatar {
    margin-top: -160px;
}

.perfil-title {
    font-size: 30px;
    font-weight: 700;
    color: #313131;
    margin-top: 5px;
}

.btn-link-perfil {
    color: #0000006e;
    font-weight: 600;
    background-color: #3131311a;
}

.btn-link-perfil.active {
    color: #FFF;
    background-color: var(--primary);
}

.btn-link-perfil:hover, .btn-link-perfil:focus {
    color: #FFF !important;
    background-color: var(--primary);
}

.btn-logos-perfil {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}

.perfil-content-row {
    padding: 0px 120px;
    margin-top: 30px;
    overflow: hidden !important;
    width: 100%;
}

.ant-image:has(.galeria-image) {
    width: 100% !important;
}
.ant-image:has(.galeria-image-profile) {
    width: 100% !important;
}

.galeria-image {
    object-fit: cover;
    height: 250px !important;
    width: 100% !important;
}

.galeria-image-profile {
    object-fit: cover;
    height: 180px !important;
    width: 100% !important;
}

.banner-profile {
    object-fit: cover;
    object-position: center;
}

@media (max-width: 768px) {
    .admin-sidebar {
        display: none;
    }
    .galeria-image {
        height: 180px !important;
    }
    .timeline-carousel {
        height: 300px !important;
    }
    .timeline-carousel .ant-image {
        height: 300px !important;
    }
    .timeline-carousel .ant-image .ant-image-img{
        height: 300px !important;
    }
    .ant-list-item-action {
        margin-left: 20px !important;
    }
    .perfil-footer {
        width: 80% !important;
    }
    .perfil-content {
        padding: 0px 0px;
    }
    .perfil-content-row {
        padding: 0px 5px;
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-top: 15px !important;
    }
    .equipe-text {
        text-align: justify;
    }
    .equipe-carousel {
        padding: 0px 10px;
    }
}

.float-right {
    float: right;
}

.task-title {
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 700;
    color: #d10000;
}

.task-text {
    font-size: 12px;
    color: #000;
}

.task-client {
    font-size: 10px;
    color: #0000008e;
}

.ind-title {
    text-transform: uppercase;
    color: #00000062;
    font-size: 11px !important;
    text-align: center;
}

.ind-text {
    font-size: 22px !important;
    text-align: center;
    font-weight: 600;
}

.ind-text.a-fazer {
    color: #226fff !important; 
}
.ind-text.em-andamento {
    color: #FFC222 !important; 
}
.ind-text.concluido {
    color: #008000 !important;
}
.ind-text.atrasados {
    color: #8B0000 !important; 
}

.home-row-page-version {
    font-weight: 700;
    border-bottom: var(--primary) 1px solid;
    margin-top: 40px;
    text-align: right;
}

.title-prest {
    font-size: 20px;
    font-weight: 600;
}

.prestacao-titulo {
    font-weight: 600;
    margin: 0px !important;
}

.prestacao-valor {
    border-bottom: 1px solid var(--primary);
    margin: 0px !important;
}

.prestacao-titulo.principal, .prestacao-valor.principal {
    font-size: 16px;
}

.prestacao-titulo.principal {
    color: var(--hover);
}

.dash-banner {
    height: 250px;
    width: 100%;
    background-color: var(--primary);
    border-radius: 8px;
    overflow: hidden;
}

.dash-banner .image-banner {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 250px;
}