/* BOTOES */
.btn-primary {
    background-color: var(--primary);
}
.btn-primary:hover, .btn-primary:focus {
    background-color: var(--hover) !important;
}
.btn-outline {
    background-color: transparent !important;
    border: 1px solid #ffffffa6 !important;
    color: #ffffffa6 !important;
}
.btn-outline:hover, .btn-outline:focus {
    background-color: transparent !important;
    border: 1px solid #fff !important;
    color: #fff !important;
}
.btn-default {
    border-color: var(--primary) !important;
}

.btn-danger {
    background-color: #d10000 !important;
}
.btn-danger:hover, .btn-danger:focus {
    background-color: #720000 !important;
}
.btn-link {
    color: var(--link) !important;
}
.btn-link:hover, .btn-link:focus {
    color: var(--primary) !important;
}

/* WEB */
.web-body {
    height: 100vh !important;
    overflow: hidden !important;
}

.web-content {
   overflow-y: scroll;
   height: 100vh;
   padding-bottom: 85px;
}

/* HOME */
.home-carousel-item {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    height: 88vh !important;
}

.home-carousel-item-shadow {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    height: 100% !important;
    padding: 0px 200px !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.home-carousel button {
    background-color: var(--primary) !important;
}

.home-carousel-item-title {
    text-transform: uppercase !important;
    color: #fff !important;
    font-size: 45px !important;
    font-weight: 700 !important;
    margin-bottom: 25px !important;
    text-align: center !important;
}

.home-carousel-item-subtitle {
    text-transform: uppercase !important;
    color: #ddd !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    text-align: center !important;
    margin-bottom: 25px !important;
}

.home-row-page {
    padding: 60px 150px !important;
}

.plan-row-page {
    padding: 60px 20px !important;
}

.home-row-breadcrumb {
    padding: 20px 150px !important;
}

.home-row-page-footer {
    padding: 40px 150px !important;
}

.home-row-title {
    font-weight: 700 !important;
    font-size: 34px !important;
    margin-bottom: 20px !important;
}

.home-row-subtitle {
    text-align: justify !important;
    font-size: 16px;
    margin-bottom: 20px !important;
}

.home-col-light {
    background-color: #f8f9fa !important;
}

.home-col-red {
    background-color: var(--primary) !important;
}

.home-col-copy {
    background-color: #000;
    padding: 15px;
}

.home-copy {
    color: #FFF;
    text-align: center;
}

.home-col-black {
    background-color: #1b1b1b !important;
}

.home-row-page-grey {
    background-color: #252525 !important;
    border-radius: 8px;
    padding: 48px;
    margin-top: 30px;
}

.home-col-white {
    border-top: 1px solid #c9c9c9be !important;
    border-bottom: 1px solid #c9c9c9be !important;
}

.home-row-page-text {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px;
    text-align: center;
    color: #444444 !important;
}

.home-row-page-text-center {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}

.home-row-page-text-grey {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}

.home-row-page-text-center span {
    color: var(--primary);
}

.home-row-page-text-title {
    text-align: center;
    color: #1b1b1b;
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}

.home-row-page-text-title::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #e03a3c;
    bottom: 0;
    left: calc(50% - 25px);
}

.color-white {
    color: #FFFFFF !important;
}

.color-grey {
    color: #aaaaaa !important;
}

.home-row-card {
    width: 70%;
    border: var(--primary) 1px solid;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
}

.home-row-card .home-row-page-text {
    margin-top: 20px;
}

.home-row-page-footer-text {
    color: white !important;
    font-size: 12px !important;
    margin-bottom: 0 !important;
    font-family: "Raleway", sans-serif !important;
}

.home-row-page-footer-social {
    background: #2b2b2b;
    color: #fff;
    padding: 2px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    transition: 0.3s;
}

.home-row-page-footer-social:hover {
    background-color: var(--primary);
}

.home-row-page-footer-title {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 3px;
    color: white;
}

.home-row-page-footer-title::after {
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 2px;
    background: #e03a3c;
    bottom: 0;
    left: 0;
}

.home-row-page-footer-link {
    margin-top: 10px;
    color: #aaaaaa;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.home-row-page-footer-link:hover {
    color: #fff;
}

.home-breadcrumb-link {
    color: #aaaaaa;
}

.home-breadcrumb-link.active {
    color: #fff;
}

.home-breadcrumb .ant-breadcrumb-separator {
    color: var(--primary);
}

.home-breadcrumb-title {
    text-align: end;
    color: #fff;
    font-size: 30px;
}

@media (max-width: 1100px) {
    .home-row-page {
        padding: 30px !important;
    }
}

@media (max-width: 500px) {

    .home-carousel-item {
        height: 93vh !important;
    }

    .home-carousel-item-shadow {
        padding: 0px 20px !important;
    }

    .home-carousel-item-title {
        font-size: 30px !important;
        margin-bottom: 25px !important;
    }
    
    .home-carousel-item-subtitle {
        font-size: 16px !important;
    }

    .home-row-subtitle {
        text-align: center !important;
    }

    .home-row-page-about {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .home-row-page-grey {
        padding: 48px 10px;
    }

    .home-row-breadcrumb {
        padding: 20px 20px !important;
    }

    .home-row-page-footer {
        padding: 20px 20px !important;
    }

    .home-breadcrumb-title {
        font-size: 18px;
    }

    .home-row-page-footer .home-row-col {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .web-content {
        padding-bottom: 60px;
    }
     
}

/* ABOUT */

.about-row-title {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1b1b1b;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
}

.about-row-title::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #e03a3c;
    margin: 4px 10px;
}

.about-row-subtitle {
    text-align: justify !important;
    font-size: 16px;
    margin-bottom: 10px !important;
    display: flex;
    align-items: flex-start;
}

.about-list svg {
    position: absolute;
    left: 0;
    top: 20px;
    font-size: 20px;
    color: #e03a3c;
}

.about-row-card {
    width: 90%;
    border: rgba(0, 0, 0, 0.363) 1px solid;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    transition: 0.3s;
    height: 180px;
}

.about-row-card:hover {
    border-color: var(--primary);
}

.about-row-page-title {
    font-size: 20px !important;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
}

.about-row-card-flip {
    width: 90%;
    border: rgba(0, 0, 0, 0.363) 1px solid;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    transition: 0.3s;
    height: 100px;
}

.about-row-card-flip.active {
    background-color: var(--primary);
    border-color: var(--primary);
}

@media (max-width: 500px) {
    .about-row-card-flip {
        padding: 15px;
    }
    .about-row-card {
        padding: 15px;
    }
    .plan-row-page {
        padding: 60px 20px !important;
    }
    .invert {
        flex-direction: column-reverse;
    }
}

.plan-text {
    text-align: center;
    font-weight: 500;
    color: var(--primary);
    padding: 10px;
    background-color: #FFF;
    border-radius: 15px;
    transition: 0.2s;
}

.plan-text:hover {
    background-color: var(--primary);
    color: #FFF;
}


.plan-row-title {
    font-weight: 700 !important;
    font-size: 24px !important;
    margin-bottom: 20px !important;
}

.plan-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 100%;
    flex-direction: column;
}

.plan-card-2 {
    border-radius: 8px;
    padding: 18px 10px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.plan-card-title {
    text-transform: uppercase;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 700;
}

.plan-card-text {
    color: #FFF;
    text-align: center;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.plan-card-price {
    color: #FFF;
    text-align: center;
    font-size: 36px;
    font-weight: 500;
}

.plan-card-price span {
    font-size: 18px;
    font-weight: 300;
    color: #FFF;
}

.plan-card-button {
    position: absolute;
    bottom: 25px;
}

@media (max-width: 500px) {
    .plan-row-title {
        font-size: 16px !important;
        text-align: center;
    }
    .btn-center {
        display: block;
    }
}

.auth-content {
    height: 100vh;
    background-color: #d3d3d38a;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-box-login {
    background-color: #fff;
    height: 450px;
    width: 900px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.274);
}

.auth-col {
    width: 40% !important;
    background-color: #ffffff !important;
    transition: 0.2s !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}

.auth-col.active {
    width: 60% !important;
    background-color: var(--primary) !important;
}

.auth-col.all {
    width: 100% !important;
    background-color: var(--primary) !important;
}

.auth-col .auth-col-title {
    color: var(--primary);
}

.auth-col.active .auth-col-title {
    color: #FFF;
}

.auth-col-title {
    font-size: 30px;
    font-weight: 700;
}

.auth-social {
    background-color: var(--primary) !important;
    color: #fff !important;
    padding: 5px !important;
    border-radius: 50% !important;
    border: #fff 1px solid !important;
    transition: 0.3s !important;
    width: 36px !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-social:hover {
    color: var(--primary) !important;
    background-color: #fff !important;
    border-color: var(--primary) !important;
}

.auth-input {
    margin-top: 20px;
    width: 300px;
    color: #757575 !important;
}

.auth-input, .auth-input input {
    background-color: #f5f5f5;
}

@media (max-width: 500px) {
 
    .auth-box-login {
        width: 100vw;
        height: 100vh;
        border-radius: 0px;
    }

    .auth-col, .auth-col.active {
        width: 100% !important;
    }

    .auth-col {
        height: 40%;
    }

    .auth-col.active {
        height: 60%;
    }

}

.home-col-out-red {
    border: 1px solid var(--primary);
    border-radius: 8px;
    padding: 18px 10px;
}

.plan-title {
    color: #2b2b2b !important;
    font-weight: bold;
    font-size: 40px;
}

.plan-title span {
    color: #FFF !important;
    background-color: var(--primary);
    padding: 3px 8px;
}

.plan-subtitle {
    color: #AFADAD !important;
    font-size: 30px;
    margin-bottom: 20px;
}

.plan-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 500px) {
    
    .home-col-out-red {
        padding: 30px 20px;
    }

    .plan-card-free {
        margin-bottom: 20px;
    }

}

.contact-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.contact-icon {
    border-radius: 50%;
    border: var(--primary) 1px dashed;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.policy-row-subtitle {
    text-align: justify !important;
    font-size: 16px !important;
    margin-bottom: 20px !important;
}

.policy-row-subtitle-line {
    text-align: justify !important;
    font-size: 16px !important;
    margin-bottom: 20px !important;
    text-indent: 1.5em;
}

.policy-row-subtitle-line::before {
    content: '•';
    position: absolute;
    left: -1.5em;
}

.policy-row-title {
    font-size: 20px;
    font-weight: 500;
    padding: 0;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1b1b1b;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
}

.policy-row-title::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #e03a3c;
    margin: 4px 10px;
}

@media (max-width: 500px) {
    .policy-row-title {
        font-size: 16px;
    }
    .policy-row-title::after {
        display: block !important;
        margin: 4px 0px;
    }
}

.auth-col .btn-auth {
    border: none !important;
    box-shadow: none !important;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #fff !important;
    color: var(--primary) !important;
    transition: 0.2s;
}

.auth-col.active .btn-auth {
    background-color: var(--primary) !important;
    color: #FFF !important;
}

.auth-div {
    height: 40px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.doc-input {
    border: none !important;
    font-size: 15px;
}

.doc-input::-webkit-input-placeholder {
    color: #0000002f;
}

.doc-input:focus, .doc-input:target {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
}


.ant-btn-primary {
    background-color: var(--primary) !important;
}

.ant-btn-primary:focus, .ant-btn-primary:hover {
    background-color: var(--hover) !important;
}

.ant-btn-default:focus, .ant-btn-default:hover {
    color: var(--hover) !important;
    border-color: var(--hover) !important;
}

.ant-checkbox:focus, .ant-checkbox:hover {
    border-color: var(--primary) !important;
}

.ant-table-filter-trigger.active {
    color: var(--primary) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.ant-pagination-item-active {
    border-color: var(--primary) !important;
}

.ant-pagination-item-active a {
    color: var(--primary) !important;
}

.ant-spin .ant-spin-dot-item {
    background-color: var(--primary) !important;
}
  
.btn-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0808089d;
    margin-right: 8px;
}
  
.btn-nav:hover, .btn-nav:focus {
    color: #080808fa !important;
}
  
.ant-upload.ant-upload-select {
    width: 100%;
}
  
.ant-upload {
    width: 100%;
}

.web-perfil {
    background-color: #f0f2f5 !important;
    padding-bottom: 0px !important;
}

.page-builder-button {
    width: 80px;
    height: 80px;
}

.page-builder-row > span {
    position: absolute;
    bottom: -16px;
    background-color: #FFF;
    color: #00000067;
    padding: 5px;
    text-transform: uppercase;
}

.page-builder-trash {
    color: #00000067;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -36px;
}

.page-builder-row {
    padding: 20px;
    border: #0000001e 1px solid;
    border-radius: 15px;
    justify-content: space-around;
}
  

.page-builder-row-select {
    padding: 20px;
    border: #0000001e 1px solid;
    border-radius: 15px;
    margin-bottom: 15px;
    transition: 0.2s;
}

.page-builder-column {
    padding: 20px;
    border: #0000001e 1px solid;
    transition: 0.2s;
}

.page-builder-column-op {
    margin-top: -15px;
}

.page-builder-column:hover, .page-builder-row-select:hover {
    border-color: var(--color03-alt);
}

.addButtonPage {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-page-builder {
    color: #00000031 !important;
    cursor: pointer;
    transition: 0.2s;
    margin: 5px;
}

.close-page-builder:hover, .close-page-builder:focus {
    color: var(--color03) !important;
}