header.header-meu-site {
    width: 100%;
    background-color: #fff;
    border: 1px solid rgb(240, 240, 240);
    border-radius: 8px;
    padding: 12px;
    margin: 0 4px;
    display: flex;
    align-items: center;
    gap: 12px;
}

header.header-meu-site img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
}

header.header-meu-site div {
    gap: 12px;
}

header.header-meu-site .about {
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    padding-left: 12px;
}

header.header-meu-site div div {
    display: flex;
    align-items: center;
}

header.header-meu-site p {
    margin: 0;
}

header.header-meu-site span {
    background: rgba(0, 0, 0, 0.06);
    padding: 4px;
    margin: 0 4px;
    border-radius: 4px;
}

header.header-meu-site .separador {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 767px) {
    header.header-meu-site {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    header.header-meu-site img {
        width: 100px;
        height: 100px;
    }

    header.header-meu-site .about {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-left: none;
        border-top: 1px solid rgba(0, 0, 0, 0.25);
        padding: 12px 0 0;
    }

    header.header-meu-site div div {
        display: flex;
        flex-direction: column;
    }

    header.header-meu-site .especialidades {
        gap: 4px;
    }

    header.header-meu-site .contato {
        display: grid;
        justify-items: start;
        grid-template: repeat(2, auto) / repeat(2, auto);
    }

    header.header-meu-site .contato svg {
        grid-area: auto/1;
        justify-self: end;
    }

    header.header-meu-site .especialidades-text {
        text-align: center !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    header.header-meu-site .separador {
        display: none;
    }

    .divisor-simples {
        border-top: 1px solid rgba(0, 0, 0, 0.25);
        padding: 12px 0 0;
        width: 100%;
    }
}